<template>
  <router-view></router-view>
</template>

<style lang="scss">
#app {
  margin: 0;
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
}

span {
  cursor: default;
}
</style>

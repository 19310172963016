<template>
    <div class="login-view">
        <div ref="left" class="left">
            <canvas ref="rainCanvas" class="rain"></canvas>
            <canvas ref="dashedCanvas" class="dashed"></canvas>
            <div class="earth">
                <div class="logo"></div>
            </div>
            <div class="circle1"></div>
            <div class="circle2 top"></div>
            <div class="circle3 top"></div>
            <div class="circle4 bottom"></div>
            <div class="circle5 bottom"></div>
        </div>
        <div class="right">
            <div class="login-form">
                <div class="head">
                    <strong>大数据看板</strong>
                </div>
                <div class="form">
                    <div class="user-box">
                        <input type="text" name="username" required v-model="userName"><label>用户名</label>
                    </div>
                    <div class="user-box">
                        <input type="password" name="password" required v-model="passWord"><label>密码</label>
                    </div>
                    <div class="user-box">
                        <input type="text" name="verifycode" required v-model="verifyCode"
                            style=" width: calc(200/1920*100vw);"><label>验证码</label>
                        <img style=" width: calc(80/1920*100vw); height: calc(40/1920*100vw);position: absolute;left: 60%;cursor: pointer;"
                            ref="verifyCodeImg" alt="点击更换" src='http://47.96.110.198:9010/login/getVerifyCodeImage'
                            title="点击更换" @click="VerifyCodeImageChange()">
                    </div>
                    <div class="btn">
                        <button @click="loginUser()">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <strong>登录</strong>
                        </button>
                        <button>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <strong>修改密码</strong>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, onBeforeUnmount } from 'vue'
import { ElMessage } from 'element-plus'

const left = ref(null)
const rainCanvas = ref(null)
const dashedCanvas = ref(null)
const updateCanvasSize = () => {
    const leftElement = left.value;
    const rainCanvasElement = rainCanvas.value
    const dashedCanvasElement = dashedCanvas.value;
    rainCanvasElement.width = leftElement.clientWidth;
    rainCanvasElement.height = leftElement.clientHeight;
    dashedCanvasElement.width = leftElement.clientWidth;
    dashedCanvasElement.height = leftElement.clientHeight;
    drawDashedLines(dashedCanvasElement, dashedCanvasElement.width, dashedCanvasElement.height);
    rainBg(rainCanvasElement, rainCanvasElement.width, rainCanvasElement.height)
};
function drawDashedLines(canvas, w, h) {
    const ctx = canvas.getContext('2d')
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    drawDashedLine(ctx, w / 5.4, h / 5.5, w / 5.4, h / 1.8 * 5 / 6, w / 5 * 2, h / 1.8, [0, "#54e2e6", 1, "#065261"])
    drawDashedLine(ctx, w / 3.3, h / 3.5, w / 3.3, h / 2 * 5 / 6, w / 2.8, h / 2.1, [0, "#e08d03", 1, "#2e6a5c"])
    drawDashedLine(ctx, w / 2.1, h / 9, w / 2.5, h / 5.2, w / 2.5, h / 3.5, [0, "#e08d03", 1, "#2e6a5c"])
    drawDashedLine(ctx, w / 1.6, h / 4.3, w / 1.46, h / 2.8, w / 1.54, h / 2.3, [0, "#e08d03", 1, "#2e6a5c"])
    drawDashedLine(ctx, w / 1.5, h / 7, w / 1.3, h / 3, w / 1.67, h / 1.9, [0, "#e08d03", 1, "#2e6a5c"])
    drawDashedLine(ctx, w / 1.3, h / 3, w / 5 * 3.8, h / 2.3, w / 5 * 2.9, h / 1.8, [0, "#e08d03", 1, "#2e6a5c"])
}
function drawDashedLine(ctx, startX, startY, controlX, controlY, endX, endY, gradientStops) {
    ctx.lineWidth = 3
    ctx.setLineDash([3, 3])
    ctx.fillStyle = '#93f8fb'
    ctx.shadowColor = '#93f8fb'
    ctx.shadowBlur = 15
    ctx.save()

    const grd = ctx.createLinearGradient(startX, startY, endX, endY)
    grd.addColorStop(gradientStops[0], gradientStops[1])
    grd.addColorStop(gradientStops[2], gradientStops[3])
    ctx.strokeStyle = grd

    ctx.beginPath()
    ctx.moveTo(endX, endY)
    ctx.quadraticCurveTo(controlX, controlY, startX, startY)
    ctx.stroke()

    ctx.beginPath()
    ctx.moveTo(startX, startY)
    ctx.arc(startX, startY, 5, 0, Math.PI * 2)
    ctx.fill()

    ctx.restore()
}
function rainBg(canvas, w, h) {
    const ctx = canvas.getContext('2d')
    const random = (min, max) => Math.random() * (max - min) + min
    class RainDrop {
        constructor() {
            this.init();
        }
        init() {
            this.x = random(0, w);
            this.y = h;
            this.vy = random(4, 5);
            this.hit = 0;
            this.size = h * 0.0024;
        }
        draw() {
            if (this.y > this.hit) {
                const linearGradient = ctx.createLinearGradient(this.x, this.y, this.x, this.y + this.size * 30);
                linearGradient.addColorStop(0, '#14789c');
                linearGradient.addColorStop(1, '#090723');
                ctx.fillStyle = linearGradient;
                ctx.fillRect(this.x, this.y, this.size, this.size * 30);
            }
            this.update();
        }
        update() {
            if (this.y > this.hit) {
                this.y -= this.vy;
            } else {
                this.init();
            }
        }
    }
    const rs = Array.from({ length: 8 }, (_, i) => {
        const r = new RainDrop()
        setTimeout(() => rs.push(r), i * 300)
        return r
    })

    const anim = () => {
        ctx.clearRect(0, 0, w, h)
        rs.forEach(r => r.draw())
        requestAnimationFrame(anim)
    }
    anim()
}

onMounted(() => {
    VerifyCodeImageChange();
    updateCanvasSize();
    window.addEventListener('resize', updateCanvasSize);
})
onBeforeUnmount(() => {
    window.removeEventListener('resize', updateCanvasSize)
})

const userName = ref('')
const passWord = ref('')
const verifyCode = ref('')
async function loginUser() {
    if (userName.value == '' || passWord.value == '') {
        ElMessage({
            message: '账号或密码不为空！',
            type: 'warning',
        })
    } else if (verifyCode.value == '') {
        ElMessage({
            message: '请输入验证码！',
            type: 'warning',
        })
    } else {
        try {
            const response = await fetch('http://47.96.110.198:9010/login/userLogin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userName: userName.value,
                    password: btoa(passWord.value),
                    verifyCode: verifyCode.value,
                })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            console.log(data.data.type);
            if (data.code != '2000') {
                ElMessage.error('登录失败！');
            } else {
                userName.value=''
                passWord.value=''
                verifyCode.value=''
                ElMessage.success('登录成功！');
                if(data.data.type==1){
                    document.body.style.display = 'none';
                    window.location.replace(`http://47.96.110.198:10014?token=${data.data.token}&type=${data.data.type}`);
                }
                else if(data.data.type==3){
                    document.body.style.display = 'none';
                    window.location.replace(`http://47.96.110.198:10011?token=${data.data.token}&type=${data.data.type}`);
                }
                else if(data.data.type==4){
                    document.body.style.display = 'none';
                    window.location.replace(`http://47.96.110.198:10013?token=${data.data.token}&type=${data.data.type}`);
                }
            }
        } catch (error) {
            console.error('Error:', error);
            ElMessage.error('请求失败，请稍后重试！');
        }
    }
}

const verifyCodeImg = ref(null)
function VerifyCodeImageChange() {
    let timestamp = Date.now();
    verifyCodeImg.value.src = `http://47.96.110.198:9010/login/getVerifyCodeImage?time=${timestamp}`;
}
</script>

<style scoped lang="scss">
@import '../style/left.css';
@import '../style/right.css';

.login-view {
    width: 100vw;
    height: 100vh;
    background-image: url('../assets/loginBG.jpg');
    background-size: 100vw 100vh;
    display: flex;
    flex-direction: row;
}
</style>